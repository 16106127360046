@import '../../assets/scss/color.scss';

.navbar-light {
    background: $white-color !important;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
}

.is-current {
    .nav-link {
        color: $primary-color !important;
    }
}