@import "../../../assets/scss/color";

.flash-message {
  background: $white-color;
  text-align: left;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03);
  border-radius: 6px;

  .flash-message-icon {
    svg {
      width: 32px;
      height: 32px;
      color: $primary-color;
    }
  }

  .flash-message-title {
    color: $primary-color;
  }
}