@import "./color.scss";
.ant-btn {
  font-weight: bold !important;
  text-transform: uppercase !important;
  &:hover {
    color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
}

.ant-btn-primary {
  background-color: #40a9ff !important;
  border-color: #40a9ff !important;
  &:hover {
    background-color: #6abcff !important;
    border-color: #6abcff !important;
    color: $white-color !important;
  }
}

.disabled-btn {
  pointer-events: none;
  opacity: 0.7;

  &:hover {
    background-color: inherit;
    color: inherit;
    border-color: inherit;
  }
}
