$white-color: #fff;
$black-color: #333333;
$light-black-color: #666666;
$off-white-color: #e7e6e6;
$light-grey-color: #f8f8f8;
$yellow-color: #fae013;
$light-red: #ff5a5f;
$dark-red: #df3c47;

$primary-color: #3f6cb1;
$secondary-color: $yellow-color;

$btn-primary: $light-red;
$btn-hover: $dark-red;
