@import "../../assets/scss/color.scss";

.registration_type {
  width: 105%;
}

.Banner__Image {
  position: relative;
  padding: 50px 0;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/banner-bg.png");
  z-index: 2;
  min-height: 100vh;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.8;
    z-index: -1;
  }
}

.Heading__Border {
  height: 5px;
  width: 70px;
  background: $secondary-color;
}

.flash-message {
  font-size: 16px !important;

  .anticon-info-circle {
    top: 11px;
  }
}

.banner-flash-message {
  top: 66px;
}

.link {
  color: $primary-color;

  &:hover {
    color: $secondary-color;
    text-decoration: none;
  }
}

.Fees__Table {
  border: 1px solid $off-white-color;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

  > ul {
    position: relative;
    border-right: 1px solid $off-white-color;

    &:last-child {
      border-right: 0 none;
    }
  }
}

.Fees__List {
  .List__Title {
    background: $primary-color !important;
    color: $light-black-color;
  }

  li {
    padding: 10px;
    border-bottom: 1px solid $off-white-color;

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .Fees__Table__Heading {
    padding: 8px 25px;
    border-bottom: 1px solid $off-white-color;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .Fees__Table__Heading {
    border-bottom: 10px solid $off-white-color;
  }
}

// blur effect for early birds
//.Fees__List-early-birds {
//
//    p.lead {
//        opacity: .3;
//    }
//
//    li {
//
//        & > span {
//            opacity: .3;
//        }
//    }
//}

.Offline__Form {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  background: $white-color;
  padding: 15px;
}

.comittee-img {
  height: 195px;
  width: 195px;
}

.Tab__Pills {
  li {
    background: #f2f2f2;
    padding: 10px 40px;
    cursor: pointer;

    &:hover {
      background: $primary-color;
      color: $white-color;
    }
  }

  .Active__Tab {
    background: $primary-color;
    color: $white-color;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 20px solid $primary-color;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.Tab__content {
  .card {
    border: 0 none;
  }
}

.Countdown {
  color: $white-color;

  .Countdown__Title {
    font-size: 40px;
  }
}

.Tab__content__List {
  position: relative;

  //ul {
  //    &::before {
  //        content: '';
  //        position: absolute;
  //        left: 0;
  //        width: 3px;
  //        height: 100%;
  //        background: $primary-color;
  //        left: 16px;
  //    }
  //}

  li {
    // border-bottom: 1px solid $off-white-color;
    padding: 7px 10px;

    p {
      margin: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    i {
      position: relative;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 2px;
      }
    }
  }
}

.Drawer__Button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 11;
}

.Image__Slider {
  overflow: hidden;
}

.sticky-bottom-floated-container {
  position: fixed;
  bottom: 48px;
  left: 0;
  right: 0;
  z-index: 1;
}

.floated-action-btn {
  min-width: 160px;
}

@media (max-width: 768px) {
  .Tab__Pills {
    li {
      padding: 10px;
    }
  }

  .Banner__Image {
    .icon-list-item {
      .anticon {
        font-size: 25px !important;
      }
    }
  }

  .Image__Slider {
    padding-bottom: 30px;
  }

  .slick-dots {
    bottom: -30px !important;
  }
}
@media (max-width: 991px) {
  .Fees__Table {
    border: 0 none;
    box-shadow: none;

    .Fees__Table__Heading {
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
    }
  }
}

.acknowledgement-image {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.city-attraction-bg {
  background-image: url("../../assets/images/city-attrection/city-attrection-bg.jpg");
  min-height: 450px;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

// blur effect for early birds
//@media (max-width: 767px) {
//    .Fees__List-early-birds {
//        opacity: .3;
//    }
//}

@media (min-width: 992px) {
  .banner-flash-message {
    right: 24px;
    position: absolute;
    top: 66px;
    max-width: 320px;
  }
}

.heading-small {
  font-size: 1.5rem !important;
}

.avatar-md-container {
  max-width: 70px !important;
  min-width: 70px;
  height: 70px;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.padding-5 {
  padding: 5px !important;
}
