@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-image-lightbox/style.css";
@import "./assets/scss/bootstrap.min.css";
@import "./assets/scss/color.scss";
@import "./assets/scss/button.scss";
@import "./assets/scss/typography.scss";
@import "./assets/scss/theme.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $light-black-color !important;
}

.slick-dots {
  bottom: -4px !important;
}

#committee,
#registration {
  overflow: hidden;
}
