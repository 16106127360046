h1 {
    font-size: 60px !important;
}

@media (max-width: 768px) {
    h1 {
        font-size: 30px !important;
    }
    h2 {
        font-size: 1.5rem !important;
    }
    .lead {
        font-size: 14px !important;
    }
    span {
        font-size: 13px;
        display: block;
        line-height: 14px;
    }
    p {
        font-size: 0.8rem;
    }
    .link {
        font-size: 14px;
    }
    table {
        font-size: 13px;
    }
}