.Bottom__Footer {
    border-top: 1px solid #e8e8e8;
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-logo-link {
      svg {
          width: 137px;
      }
    }
}