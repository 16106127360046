.city-attraction-slider {

  .slick-slide img {
    cursor: pointer;
  }

  .slick-track {
    display: flex;
  }

  .slick-initialized .slick-slide {
    margin: auto;
  }


  .slick-prev, .slick-next {
    background-color: unset;
    border-radius: 50%;
    width: 25px;
    height: 25px;

    &:before {
      color: #404040;
      font-size: 25px;
    }
  }
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

@media (min-width: 768px) {
  .city-attraction-slider{
    .slick-slider {
      max-width: 50%;
    }
  }
}