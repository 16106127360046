@import "../scss/color.scss";

.text-secondary {
  color: $btn-primary;
}

.text-black {
  color: $black-color;
}

.text-yellow {
  color: $yellow-color !important;
}

.text-primary {
  color: $primary-color;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $light-grey-color !important;
}

.bg-blue {
  background-color: $secondary-color;
}

.link {
  color: $primary-color;

  &:hover {
    color: $dark-red;
    text-decoration: none;
  }
}

.divider {
  height: 1px;
  background: gainsboro;
  width: 100%;
}
